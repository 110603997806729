import React from 'react';
import { Icon } from 'antd';

import { json, totalBoxStyle } from '../Config';

const result1 =
  json[json.length - 6].activity_interaction.qna +
  json[json.length - 6].activity_interaction.chat +
  json[json.length - 6].activity_interaction.voting +
  json[json.length - 6].activity_interaction.debate +
  json[json.length - 6].activity_interaction.wiki;
const result2 =
  json[json.length - 5].activity_interaction.qna +
  json[json.length - 5].activity_interaction.chat +
  json[json.length - 5].activity_interaction.voting +
  json[json.length - 5].activity_interaction.debate +
  json[json.length - 5].activity_interaction.wiki;
const result3 =
  json[json.length - 4].activity_interaction.qna +
  json[json.length - 4].activity_interaction.chat +
  json[json.length - 4].activity_interaction.voting +
  json[json.length - 4].activity_interaction.debate +
  json[json.length - 4].activity_interaction.wiki;
const result4 =
  json[json.length - 3].activity_interaction.qna +
  json[json.length - 3].activity_interaction.chat +
  json[json.length - 3].activity_interaction.voting +
  json[json.length - 3].activity_interaction.debate +
  json[json.length - 3].activity_interaction.wiki;
const result5 =
  json[json.length - 2].activity_interaction.qna +
  json[json.length - 2].activity_interaction.chat +
  json[json.length - 2].activity_interaction.voting +
  json[json.length - 2].activity_interaction.debate +
  json[json.length - 2].activity_interaction.wiki;
const result6 =
  json[json.length - 1].activity_interaction.qna +
  json[json.length - 1].activity_interaction.chat +
  json[json.length - 1].activity_interaction.voting +
  json[json.length - 1].activity_interaction.debate +
  json[json.length - 1].activity_interaction.wiki;

const TotalActivityInteraction =
  result1 + result2 + result3 + result4 + result5 + result6;

const SemesterAverage = TotalActivityInteraction / 6;

export default function Widget() {
  return (
    <div>
      <div align='right'>
        학기 평균 : {Math.round(SemesterAverage).toLocaleString()}
      </div>
      <div style={totalBoxStyle} align='center'>
        <h1>
          <Icon type='deployment-unit' />{' '}
          {TotalActivityInteraction.toLocaleString()}
        </h1>
      </div>
    </div>
  );
}
