import React from 'react';
import { Icon } from 'antd';

import { json, totalBoxStyle } from '../Config';

const result1 =
  json[json.length - 6].activity_resource.notice +
  json[json.length - 6].activity_resource.file +
  json[json.length - 6].activity_resource.folder +
  json[json.length - 6].activity_resource.outline +
  json[json.length - 6].activity_resource.webdoc +
  json[json.length - 6].activity_resource.url +
  json[json.length - 6].activity_resource.movie;
const result2 =
  json[json.length - 5].activity_resource.notice +
  json[json.length - 5].activity_resource.file +
  json[json.length - 5].activity_resource.folder +
  json[json.length - 5].activity_resource.outline +
  json[json.length - 5].activity_resource.webdoc +
  json[json.length - 5].activity_resource.url +
  json[json.length - 5].activity_resource.movie;
const result3 =
  json[json.length - 4].activity_resource.notice +
  json[json.length - 4].activity_resource.file +
  json[json.length - 4].activity_resource.folder +
  json[json.length - 4].activity_resource.outline +
  json[json.length - 4].activity_resource.webdoc +
  json[json.length - 4].activity_resource.url +
  json[json.length - 4].activity_resource.movie;
const result4 =
  json[json.length - 3].activity_resource.notice +
  json[json.length - 3].activity_resource.file +
  json[json.length - 3].activity_resource.folder +
  json[json.length - 3].activity_resource.outline +
  json[json.length - 3].activity_resource.webdoc +
  json[json.length - 3].activity_resource.url +
  json[json.length - 3].activity_resource.movie;
const result5 =
  json[json.length - 2].activity_resource.notice +
  json[json.length - 2].activity_resource.file +
  json[json.length - 2].activity_resource.folder +
  json[json.length - 2].activity_resource.outline +
  json[json.length - 2].activity_resource.webdoc +
  json[json.length - 2].activity_resource.url +
  json[json.length - 2].activity_resource.movie;
const result6 =
  json[json.length - 1].activity_resource.notice +
  json[json.length - 1].activity_resource.file +
  json[json.length - 1].activity_resource.folder +
  json[json.length - 1].activity_resource.outline +
  json[json.length - 1].activity_resource.webdoc +
  json[json.length - 1].activity_resource.url +
  json[json.length - 1].activity_resource.movie;

const TotalActivityResource =
  result1 + result2 + result3 + result4 + result5 + result6;

const SemesterAverage = TotalActivityResource / 6;

export default function Widget() {
  return (
    <div>
      <div align='right'>
        학기 평균 : {Math.round(SemesterAverage).toLocaleString()}
      </div>
      <div style={totalBoxStyle} align='center'>
        <h1>
          <Icon type='container' /> {TotalActivityResource.toLocaleString()}
        </h1>
      </div>
    </div>
  );
}
