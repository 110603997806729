import React from 'react';
import { Icon } from 'antd';

import { json, totalBoxStyle } from '../Config';

const BaseMembers = 100000;
const TotalMembers =
  BaseMembers +
  json[json.length - 6].member_increase +
  json[json.length - 5].member_increase +
  json[json.length - 4].member_increase +
  json[json.length - 3].member_increase +
  json[json.length - 2].member_increase +
  json[json.length - 1].member_increase;

export default function Widget() {
  return (
    <div>
      <div align='right'>2010.4.12. ~ 현재</div>
      <div style={totalBoxStyle} align='center'>
        <h1>
          <Icon type='user' /> {TotalMembers.toLocaleString()}
        </h1>
      </div>
    </div>
  );
}
