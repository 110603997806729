import React from 'react';
import { Icon } from 'antd';

import { json, totalBoxStyle } from '../Config';

const TotalCourseOpen =
  json[json.length - 6].course.open +
  json[json.length - 5].course.open +
  json[json.length - 4].course.open +
  json[json.length - 3].course.open +
  json[json.length - 2].course.open +
  json[json.length - 1].course.open;
const SemesterAverage = TotalCourseOpen / 6;

export default function Widget() {
  return (
    <div>
      <div align='right'>
        학기 평균 : {Math.round(SemesterAverage).toLocaleString()}
      </div>
      <div style={totalBoxStyle} align='center'>
        <h1>
          <Icon type='appstore' /> {TotalCourseOpen.toLocaleString()}
        </h1>
      </div>
    </div>
  );
}
