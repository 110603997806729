import React from 'react';
import { Card, Row, Col } from 'antd';

// charts import
import CourseOpen from '../charts/CourseOpen';
import CourseOpenRatio from '../charts/CourseOpenRatio';
import CourseUse from '../charts/CourseUse';
import CourseUseRatio from '../charts/CourseUseRatio';
import CourseOpenUseRatio from '../charts/CourseOpenUseRatio';
import Enrollment from '../charts/Enrollment';
import LoginEnrollment from '../charts/LoginEnrollment';
import CoreActivity from '../charts/CoreActivity';
import CoreActivityRatio from '../charts/CoreActivityRatio';
import ActivityTotal from '../charts/ActivityTotal';
import ActivityResource from '../charts/ActivityResource';
import ActivityResource2 from '../charts/ActivityResource2';
import ActivityPerformance from '../charts/ActivityPerformance';
import ActivityPerformance2 from '../charts/ActivityPerformance2';
import ActivityInteraction from '../charts/ActivityInteraction';
import ActivityInteraction2 from '../charts/ActivityInteraction2';
//
// NavBar, Footer
import NavBar from '../../../components/views/NavBar/NavBar';
import Footer from '../../../components/views/Footer/Footer';

// common import
import TitleBox from '../Common';

// Config style import
import { gutter, backgroundStyle, chartStyle, headStyle } from '../Config';

export default function Index() {
  return (
    <div>
      <NavBar />
      <div style={backgroundStyle}>
        <TitleBox subTitle='Course' />

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='강좌개설 현황'
            >
              <CourseOpen />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='강좌개설 비율(%)'
            >
              <CourseOpenRatio />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='강좌사용 현황'
            >
              <CourseUse />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='강좌사용 비율(%)'
            >
              <CourseUseRatio />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col span={24}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='강좌개설-강좌사용 비율비교(%)'
            >
              <CourseOpenUseRatio />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='수강신청 현황'
            >
              <Enrollment />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='로그인 대비 수강신청 현황'
            >
              <LoginEnrollment />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='핵심 학습활동 현황'
            >
              <CoreActivity />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='핵심 학습활동 비율(%)'
            >
              <CoreActivityRatio />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col span={24}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='전체 활동 현황'
            >
              <ActivityTotal />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='학습자료 현황 - 학기 비교'
            >
              <ActivityResource />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='학습자료 현황 - 항목 비교'
            >
              <ActivityResource2 />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='수행활동 현황 - 학기 비교'
            >
              <ActivityPerformance />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='수행활동 현황 - 항목 비교'
            >
              <ActivityPerformance2 />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='상호작용 현황 - 학기 비교'
            >
              <ActivityInteraction />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='상호작용 현황 - 항목 비교'
            >
              <ActivityInteraction2 />
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}
