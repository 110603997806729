import React from 'react';
import { Card, Row, Col } from 'antd';

// charts import
import MemberIncrease from '../charts/MemberIncrease';
import Login from '../charts/Login';
import Pageview from '../charts/Pageview';
import Funnel from '../charts/Funnel';
import FunnelTotal from '../charts/FunnelTotal';
import Device from '../charts/Device';
import DeviceTotal from '../charts/DeviceTotal';
import Location from '../charts/Location';
//
// NavBar, Footer
import NavBar from '../../../components/views/NavBar/NavBar';
import Footer from '../../../components/views/Footer/Footer';

// common import
import TitleBox from '../Common';

// Config style import
import { gutter, backgroundStyle, chartStyle, headStyle } from '../Config';

export default function Index() {
  return (
    <div>
      <NavBar />
      <div style={backgroundStyle}>
        <TitleBox subTitle='Connection' />

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='회원증가 현황'
            >
              <MemberIncrease />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card style={chartStyle} headStyle={headStyle} title='로그인 현황'>
              <Login />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col span={24}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='페이지뷰 현황'
            >
              <Pageview />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='유입경로 비율(%)'
            >
              <Funnel />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='유입경로 종합(평균)'
            >
              <FunnelTotal />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='접속방법 비율(%)'
            >
              <Device />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='접속방법 종합(평균)'
            >
              <DeviceTotal />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col span={24}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='접속지역 비율(%)'
            >
              <Location />
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}
