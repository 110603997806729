import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const data = {
  type: 'line',
  json: {
    data1: [
      json[json.length - 6].activity_resource.notice,
      json[json.length - 6].activity_resource.file,
      json[json.length - 6].activity_resource.folder,
      json[json.length - 6].activity_resource.outline,
      json[json.length - 6].activity_resource.webdoc,
      json[json.length - 6].activity_resource.url,
      json[json.length - 6].activity_resource.movie,
      json[json.length - 6].activity_performance.assignment,
      json[json.length - 6].activity_performance.poll,
      json[json.length - 6].activity_performance.quiz,
      json[json.length - 6].activity_performance.attendance,
      json[json.length - 6].activity_performance.grade,
      json[json.length - 6].activity_interaction.qna,
      json[json.length - 6].activity_interaction.chat,
      json[json.length - 6].activity_interaction.voting,
      json[json.length - 6].activity_interaction.debate,
      json[json.length - 6].activity_interaction.wiki,
    ],
    data2: [
      json[json.length - 5].activity_resource.notice,
      json[json.length - 5].activity_resource.file,
      json[json.length - 5].activity_resource.folder,
      json[json.length - 5].activity_resource.outline,
      json[json.length - 5].activity_resource.webdoc,
      json[json.length - 5].activity_resource.url,
      json[json.length - 5].activity_resource.movie,
      json[json.length - 5].activity_performance.assignment,
      json[json.length - 5].activity_performance.poll,
      json[json.length - 5].activity_performance.quiz,
      json[json.length - 5].activity_performance.attendance,
      json[json.length - 5].activity_performance.grade,
      json[json.length - 5].activity_interaction.qna,
      json[json.length - 5].activity_interaction.chat,
      json[json.length - 5].activity_interaction.voting,
      json[json.length - 5].activity_interaction.debate,
      json[json.length - 5].activity_interaction.wiki,
    ],
    data3: [
      json[json.length - 4].activity_resource.notice,
      json[json.length - 4].activity_resource.file,
      json[json.length - 4].activity_resource.folder,
      json[json.length - 4].activity_resource.outline,
      json[json.length - 4].activity_resource.webdoc,
      json[json.length - 4].activity_resource.url,
      json[json.length - 4].activity_resource.movie,
      json[json.length - 4].activity_performance.assignment,
      json[json.length - 4].activity_performance.poll,
      json[json.length - 4].activity_performance.quiz,
      json[json.length - 4].activity_performance.attendance,
      json[json.length - 4].activity_performance.grade,
      json[json.length - 4].activity_interaction.qna,
      json[json.length - 4].activity_interaction.chat,
      json[json.length - 4].activity_interaction.voting,
      json[json.length - 4].activity_interaction.debate,
      json[json.length - 4].activity_interaction.wiki,
    ],
    data4: [
      json[json.length - 3].activity_resource.notice,
      json[json.length - 3].activity_resource.file,
      json[json.length - 3].activity_resource.folder,
      json[json.length - 3].activity_resource.outline,
      json[json.length - 3].activity_resource.webdoc,
      json[json.length - 3].activity_resource.url,
      json[json.length - 3].activity_resource.movie,
      json[json.length - 3].activity_performance.assignment,
      json[json.length - 3].activity_performance.poll,
      json[json.length - 3].activity_performance.quiz,
      json[json.length - 3].activity_performance.attendance,
      json[json.length - 3].activity_performance.grade,
      json[json.length - 3].activity_interaction.qna,
      json[json.length - 3].activity_interaction.chat,
      json[json.length - 3].activity_interaction.voting,
      json[json.length - 3].activity_interaction.debate,
      json[json.length - 3].activity_interaction.wiki,
    ],
    data5: [
      json[json.length - 2].activity_resource.notice,
      json[json.length - 2].activity_resource.file,
      json[json.length - 2].activity_resource.folder,
      json[json.length - 2].activity_resource.outline,
      json[json.length - 2].activity_resource.webdoc,
      json[json.length - 2].activity_resource.url,
      json[json.length - 2].activity_resource.movie,
      json[json.length - 2].activity_performance.assignment,
      json[json.length - 2].activity_performance.poll,
      json[json.length - 2].activity_performance.quiz,
      json[json.length - 2].activity_performance.attendance,
      json[json.length - 2].activity_performance.grade,
      json[json.length - 2].activity_interaction.qna,
      json[json.length - 2].activity_interaction.chat,
      json[json.length - 2].activity_interaction.voting,
      json[json.length - 2].activity_interaction.debate,
      json[json.length - 2].activity_interaction.wiki,
    ],
    data6: [
      json[json.length - 1].activity_resource.notice,
      json[json.length - 1].activity_resource.file,
      json[json.length - 1].activity_resource.folder,
      json[json.length - 1].activity_resource.outline,
      json[json.length - 1].activity_resource.webdoc,
      json[json.length - 1].activity_resource.url,
      json[json.length - 1].activity_resource.movie,
      json[json.length - 1].activity_performance.assignment,
      json[json.length - 1].activity_performance.poll,
      json[json.length - 1].activity_performance.quiz,
      json[json.length - 1].activity_performance.attendance,
      json[json.length - 1].activity_performance.grade,
      json[json.length - 1].activity_interaction.qna,
      json[json.length - 1].activity_interaction.chat,
      json[json.length - 1].activity_interaction.voting,
      json[json.length - 1].activity_interaction.debate,
      json[json.length - 1].activity_interaction.wiki,
    ],
  },
  names: {
    data1: json[json.length - 6].category,
    data2: json[json.length - 5].category,
    data3: json[json.length - 4].category,
    data4: json[json.length - 3].category,
    data5: json[json.length - 2].category,
    data6: json[json.length - 1].category,
  },
  labels: false,
  // custom colors
  colors: {
    data1: '#38A0FB',
    data2: '#54E7A6',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
  },
};

const axis = {
  // rotated: true,
  x: {
    type: 'category',
    categories: [
      '공지사항',
      '파일',
      '폴더',
      '개요',
      '웹문서',
      'URL',
      '동영상',
      '과제',
      '설문조사',
      '퀴즈',
      '출석부',
      '성적부',
      'Q&A',
      '채팅방',
      '투표',
      '토론방',
      '위키',
    ],
  },
};

const regions = [
  {
    axis: 'x',
    start: 0,
    end: 7,
    class: 'regionX',
  },
  {
    axis: 'x',
    start: 8,
    end: 12,
    class: 'regionX',
  },
  {
    axis: 'x',
    start: 13,
    end: 17,
    class: 'regionX',
  },
];

const grid = {
  x: {
    show: true,
  },
  y: {
    show: true,
  },
};

export default function Chart() {
  return (
    <BillboardChart data={data} axis={axis} grid={grid} regions={regions} />
  );
}
