import React from 'react';
import { Menu } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function LeftMenu(props) {
  const user = useSelector((state) => state.user);

  if (user.userData && !user.userData.isAuth) {
    return null;
  } else if (user.userData && user.userData.isAdmin === true) {
    return (
      <Menu mode={props.mode}>
        <Menu.Item key='admintotal'>
          <Link
            onClick={() =>
              props.history.push(`/charts/${user.userData.username}/total`)
            }
          >
            List
          </Link>
        </Menu.Item>
      </Menu>
    );
  } else if (user.userData && user.userData.isObserver === true) {
    return (
      <Menu mode={props.mode}>
        <Menu.Item key='monitetotal'>
          <Link
            onClick={() =>
              props.history.push(`/charts/${user.userData.username}/total`)
            }
          >
            List
          </Link>
        </Menu.Item>
      </Menu>
    );
  } else {
    return (
      <Menu mode={props.mode}>
        <Menu.Item key='chart1'>
          <Link
            onClick={() =>
              props.history.push(`/charts/${user.userData.username}/total`)
            }
          >
            Total
          </Link>
        </Menu.Item>
        <Menu.Item key='chart2'>
          <Link
            onClick={() =>
              props.history.push(`/charts/${user.userData.username}/connection`)
            }
          >
            Connection
          </Link>
        </Menu.Item>
        <Menu.Item key='chart3'>
          <Link
            onClick={() =>
              props.history.push(`/charts/${user.userData.username}/course`)
            }
          >
            Course
          </Link>
        </Menu.Item>
      </Menu>
    );
  }
}

export default withRouter(LeftMenu);
