import React from 'react';
import { Card, Row, Col } from 'antd';

// charts import
import Banner from '../../commons/Banner1';
import TotalMembers from '../charts/TotalMembers';
import TotalLogin from '../charts/TotalLogin';
import TotalPageview from '../charts/TotalPageview';
import TotalCourseOpen from '../charts/TotalCourseOpen';
import TotalCourseUse from '../charts/TotalCourseUse';
import TotalEnrollment from '../charts/TotalEnrollment';
import TotalActivityResource from '../charts/TotalActivityResource';
import TotalActivityPerformance from '../charts/TotalActivityPerformance';
import TotalActivityInteraction from '../charts/TotalActivityInteraction';
import TotalActivitySum from '../charts/TotalActivitySum';
import TotalFunnelDirectRatio from '../charts/TotalFunnelDirectRatio';
import TotalFunnelSearchRatio from '../charts/TotalFunnelSearchRatio';
import TotalFunnelRecommendRatio from '../charts/TotalFunnelRecommendRatio';
import TotalFunnelSocialRatio from '../charts/TotalFunnelSocialRatio';
import TotalDeviceDesktopRatio from '../charts/TotalDeviceDesktopRatio';
import TotalDeviceMobileRatio from '../charts/TotalDeviceMobileRatio';
import TotalDeviceTabletRatio from '../charts/TotalDeviceTabletRatio';
//
// NavBar, Footer
import NavBar from '../../../components/views/NavBar/NavBar';
import Footer from '../../../components/views/Footer/Footer';

// common import
import TitleBox from '../Common';

// Config style import
import {
  gutter,
  backgroundStyle,
  chartStyle,
  headStyle,
  headStyle2,
  headStyle3,
  headStyle4,
  headStyle5,
  bannerStyle,
} from '../Config';

export default function Index() {
  return (
    <div>
      <NavBar />
      <div style={backgroundStyle}>
        <TitleBox subTitle='Total' />

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <div style={bannerStyle}>
              <Banner />
            </div>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='전체 회원(명)'
            >
              <TotalMembers />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='전체 로그인(건)'
            >
              <TotalLogin />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle}
              title='전체 페이지뷰(건)'
            >
              <TotalPageview />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={6}>
            <Card
              style={chartStyle}
              headStyle={headStyle2}
              title='직접유입 평균비율(%)'
            >
              <TotalFunnelDirectRatio />
            </Card>
          </Col>
          <Col sm={24} md={6}>
            <Card
              style={chartStyle}
              headStyle={headStyle2}
              title='검색유입 평균비율(%)'
            >
              <TotalFunnelSearchRatio />
            </Card>
          </Col>

          <Col sm={24} md={6}>
            <Card
              style={chartStyle}
              headStyle={headStyle2}
              title='추천유입 평균비율(%)'
            >
              <TotalFunnelRecommendRatio />
            </Card>
          </Col>
          <Col sm={24} md={6}>
            <Card
              style={chartStyle}
              headStyle={headStyle2}
              title='소셜유입 평균비율(%)'
            >
              <TotalFunnelSocialRatio />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={8}>
            <Card
              style={chartStyle}
              headStyle={headStyle3}
              title='데스크톱 접속 평균비율(%)'
            >
              <TotalDeviceDesktopRatio />
            </Card>
          </Col>
          <Col sm={24} md={8}>
            <Card
              style={chartStyle}
              headStyle={headStyle3}
              title='모바일 접속 평균비율(%)'
            >
              <TotalDeviceMobileRatio />
            </Card>
          </Col>
          <Col sm={24} md={8}>
            <Card
              style={chartStyle}
              headStyle={headStyle3}
              title='태블릿 접속 평균비율(%)'
            >
              <TotalDeviceTabletRatio />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle4}
              title='전체 강좌개설(개)'
            >
              <TotalCourseOpen />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle4}
              title='전체 강좌사용(개)'
            >
              <TotalCourseUse />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle4}
              title='전체 수강신청(개)'
            >
              <TotalEnrollment />
            </Card>
          </Col>
          <Col sm={24} md={12}>
            <Card
              style={chartStyle}
              headStyle={headStyle4}
              title='전체 활동량(건)'
            >
              <TotalActivitySum />
            </Card>
          </Col>
        </Row>

        <Row gutter={gutter}>
          <Col sm={24} md={8}>
            <Card
              style={chartStyle}
              headStyle={headStyle5}
              title='전체 학습자료(건)'
            >
              <TotalActivityResource />
            </Card>
          </Col>
          <Col sm={24} md={8}>
            <Card
              style={chartStyle}
              headStyle={headStyle5}
              title='전체 수행활동(건)'
            >
              <TotalActivityPerformance />
            </Card>
          </Col>
          <Col sm={24} md={8}>
            <Card
              style={chartStyle}
              headStyle={headStyle5}
              title='전체 상호작용(건)'
            >
              <TotalActivityInteraction />
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}
