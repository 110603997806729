import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const data = {
  type: 'line',
  json: {
    data1: json[json.length - 6].pageview,
    data2: json[json.length - 5].pageview,
    data3: json[json.length - 4].pageview,
    data4: json[json.length - 3].pageview,
    data5: json[json.length - 2].pageview,
    data6: json[json.length - 1].pageview,
  },
  names: {
    data1: json[json.length - 6].category,
    data2: json[json.length - 5].category,
    data3: json[json.length - 4].category,
    data4: json[json.length - 3].category,
    data5: json[json.length - 2].category,
    data6: json[json.length - 1].category,
  },
  labels: false,
  // custom colors
  colors: {
    data1: '#38A0FB',
    data2: '#54E7A6',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
  },
};

const grid = {
  x: {
    show: true,
  },
  y: {
    show: true,
  },
};

const axis = {
  x: {
    show: true,
  },
};

export default function Chart() {
  return <BillboardChart data={data} grid={grid} axis={axis} />;
}
