import React from 'react';

const footerStyle = {
  background: '#333',
  color: '#fff',
  width: '100%',
  height: '80px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
};

const nowYear = new Date().getFullYear();

function Footer() {
  return (
    <div style={footerStyle}>
      <p>© {nowYear} - Coursemos Data Lab</p>
    </div>
  );
}

export default Footer;
