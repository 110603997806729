import React from 'react';
import { Icon } from 'antd';

import { json, totalBoxStyle } from '../Config';

// Resouce
const ResourceResult1 =
  json[json.length - 6].activity_resource.notice +
  json[json.length - 6].activity_resource.file +
  json[json.length - 6].activity_resource.folder +
  json[json.length - 6].activity_resource.outline +
  json[json.length - 6].activity_resource.webdoc +
  json[json.length - 6].activity_resource.url +
  json[json.length - 6].activity_resource.movie;
const ResourceResult2 =
  json[json.length - 5].activity_resource.notice +
  json[json.length - 5].activity_resource.file +
  json[json.length - 5].activity_resource.folder +
  json[json.length - 5].activity_resource.outline +
  json[json.length - 5].activity_resource.webdoc +
  json[json.length - 5].activity_resource.url +
  json[json.length - 5].activity_resource.movie;
const ResourceResult3 =
  json[json.length - 4].activity_resource.notice +
  json[json.length - 4].activity_resource.file +
  json[json.length - 4].activity_resource.folder +
  json[json.length - 4].activity_resource.outline +
  json[json.length - 4].activity_resource.webdoc +
  json[json.length - 4].activity_resource.url +
  json[json.length - 4].activity_resource.movie;
const ResourceResult4 =
  json[json.length - 3].activity_resource.notice +
  json[json.length - 3].activity_resource.file +
  json[json.length - 3].activity_resource.folder +
  json[json.length - 3].activity_resource.outline +
  json[json.length - 3].activity_resource.webdoc +
  json[json.length - 3].activity_resource.url +
  json[json.length - 3].activity_resource.movie;
const ResourceResult5 =
  json[json.length - 2].activity_resource.notice +
  json[json.length - 2].activity_resource.file +
  json[json.length - 2].activity_resource.folder +
  json[json.length - 2].activity_resource.outline +
  json[json.length - 2].activity_resource.webdoc +
  json[json.length - 2].activity_resource.url +
  json[json.length - 2].activity_resource.movie;
const ResourceResult6 =
  json[json.length - 1].activity_resource.notice +
  json[json.length - 1].activity_resource.file +
  json[json.length - 1].activity_resource.folder +
  json[json.length - 1].activity_resource.outline +
  json[json.length - 1].activity_resource.webdoc +
  json[json.length - 1].activity_resource.url +
  json[json.length - 1].activity_resource.movie;

const TotalActivityResource =
  ResourceResult1 +
  ResourceResult2 +
  ResourceResult3 +
  ResourceResult4 +
  ResourceResult5 +
  ResourceResult6;

// Performance
const PerformanceResult1 =
  json[json.length - 6].activity_performance.assignment +
  json[json.length - 6].activity_performance.poll +
  json[json.length - 6].activity_performance.quiz +
  json[json.length - 6].activity_performance.attendance +
  json[json.length - 6].activity_performance.grade;
const PerformanceResult2 =
  json[json.length - 5].activity_performance.assignment +
  json[json.length - 5].activity_performance.poll +
  json[json.length - 5].activity_performance.quiz +
  json[json.length - 5].activity_performance.attendance +
  json[json.length - 5].activity_performance.grade;
const PerformanceResult3 =
  json[json.length - 4].activity_performance.assignment +
  json[json.length - 4].activity_performance.poll +
  json[json.length - 4].activity_performance.quiz +
  json[json.length - 4].activity_performance.attendance +
  json[json.length - 4].activity_performance.grade;
const PerformanceResult4 =
  json[json.length - 3].activity_performance.assignment +
  json[json.length - 3].activity_performance.poll +
  json[json.length - 3].activity_performance.quiz +
  json[json.length - 3].activity_performance.attendance +
  json[json.length - 3].activity_performance.grade;
const PerformanceResult5 =
  json[json.length - 2].activity_performance.assignment +
  json[json.length - 2].activity_performance.poll +
  json[json.length - 2].activity_performance.quiz +
  json[json.length - 2].activity_performance.attendance +
  json[json.length - 2].activity_performance.grade;
const PerformanceResult6 =
  json[json.length - 1].activity_performance.assignment +
  json[json.length - 1].activity_performance.poll +
  json[json.length - 1].activity_performance.quiz +
  json[json.length - 1].activity_performance.attendance +
  json[json.length - 1].activity_performance.grade;

const TotalActivityPerformance =
  PerformanceResult1 +
  PerformanceResult2 +
  PerformanceResult3 +
  PerformanceResult4 +
  PerformanceResult5 +
  PerformanceResult6;

// Interaction
const InteractionResult1 =
  json[json.length - 6].activity_interaction.qna +
  json[json.length - 6].activity_interaction.chat +
  json[json.length - 6].activity_interaction.voting +
  json[json.length - 6].activity_interaction.debate +
  json[json.length - 6].activity_interaction.wiki;
const InteractionResult2 =
  json[json.length - 5].activity_interaction.qna +
  json[json.length - 5].activity_interaction.chat +
  json[json.length - 5].activity_interaction.voting +
  json[json.length - 5].activity_interaction.debate +
  json[json.length - 5].activity_interaction.wiki;
const InteractionResult3 =
  json[json.length - 4].activity_interaction.qna +
  json[json.length - 4].activity_interaction.chat +
  json[json.length - 4].activity_interaction.voting +
  json[json.length - 4].activity_interaction.debate +
  json[json.length - 4].activity_interaction.wiki;
const InteractionResult4 =
  json[json.length - 3].activity_interaction.qna +
  json[json.length - 3].activity_interaction.chat +
  json[json.length - 3].activity_interaction.voting +
  json[json.length - 3].activity_interaction.debate +
  json[json.length - 3].activity_interaction.wiki;
const InteractionResult5 =
  json[json.length - 2].activity_interaction.qna +
  json[json.length - 2].activity_interaction.chat +
  json[json.length - 2].activity_interaction.voting +
  json[json.length - 2].activity_interaction.debate +
  json[json.length - 2].activity_interaction.wiki;
const InteractionResult6 =
  json[json.length - 1].activity_interaction.qna +
  json[json.length - 1].activity_interaction.chat +
  json[json.length - 1].activity_interaction.voting +
  json[json.length - 1].activity_interaction.debate +
  json[json.length - 1].activity_interaction.wiki;

const TotalActivityInteraction =
  InteractionResult1 +
  InteractionResult2 +
  InteractionResult3 +
  InteractionResult4 +
  InteractionResult5 +
  InteractionResult6;

// Total
const TotalActivitySum =
  TotalActivityResource + TotalActivityPerformance + TotalActivityInteraction;
const SemesterAverage = TotalActivitySum / 6;

export default function Widget() {
  return (
    <div>
      <div align='right'>
        학기 평균 : {Math.round(SemesterAverage).toLocaleString()}
      </div>
      <div style={totalBoxStyle} align='center'>
        <h1>
          <Icon type='bar-chart' /> {TotalActivitySum.toLocaleString()}
        </h1>
      </div>
    </div>
  );
}
