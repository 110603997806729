import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const data = {
  type: 'bar',
  json: {
    data1: [
      json[json.length - 6].activity_interaction.qna,
      json[json.length - 5].activity_interaction.qna,
      json[json.length - 4].activity_interaction.qna,
      json[json.length - 3].activity_interaction.qna,
      json[json.length - 2].activity_interaction.qna,
      json[json.length - 1].activity_interaction.qna,
    ],
    data2: [
      json[json.length - 6].activity_interaction.chat,
      json[json.length - 5].activity_interaction.chat,
      json[json.length - 4].activity_interaction.chat,
      json[json.length - 3].activity_interaction.chat,
      json[json.length - 2].activity_interaction.chat,
      json[json.length - 1].activity_interaction.chat,
    ],
    data3: [
      json[json.length - 6].activity_interaction.voting,
      json[json.length - 5].activity_interaction.voting,
      json[json.length - 4].activity_interaction.voting,
      json[json.length - 3].activity_interaction.voting,
      json[json.length - 2].activity_interaction.voting,
      json[json.length - 1].activity_interaction.voting,
    ],
    data4: [
      json[json.length - 6].activity_interaction.debate,
      json[json.length - 5].activity_interaction.debate,
      json[json.length - 4].activity_interaction.debate,
      json[json.length - 3].activity_interaction.debate,
      json[json.length - 2].activity_interaction.debate,
      json[json.length - 1].activity_interaction.debate,
    ],
    data5: [
      json[json.length - 6].activity_interaction.wiki,
      json[json.length - 5].activity_interaction.wiki,
      json[json.length - 4].activity_interaction.wiki,
      json[json.length - 3].activity_interaction.wiki,
      json[json.length - 2].activity_interaction.wiki,
      json[json.length - 1].activity_interaction.wiki,
    ],
  },
  names: {
    data1: 'Q&A',
    data2: '채팅방',
    data3: '투표',
    data4: '토론방',
    data5: '위키',
  },
  labels: {
    // colors: '#333',
    show: true,
  },
  // custom colors
  colors: {
    data1: '#38A0FB',
    data2: '#54E7A6',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
    data7: '#FF66CC',
  },
};

const bar = {
  padding: 3,
};

const axis = {
  // rotated: true,
  x: {
    type: 'category',
    categories: [
      json[json.length - 6].category,
      json[json.length - 5].category,
      json[json.length - 4].category,
      json[json.length - 3].category,
      json[json.length - 2].category,
      json[json.length - 1].category,
    ],
  },
};

const grid = {
  x: {
    show: true,
  },
  y: {
    show: true,
  },
};

export default function Chart() {
  return <BillboardChart data={data} axis={axis} grid={grid} bar={bar} />;
}
