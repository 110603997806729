import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import LeftMenu from './Sections/LeftMenu';
import RightMenu from './Sections/RightMenu';
import { Drawer, Button, Icon } from 'antd';
import './Sections/Navbar.css';

import logo from '../../images/coursemos-logo-v1.png';

function NavBar({ history }) {
  const user = useSelector((state) => state.user);

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <nav
      className='menu'
      style={{ position: 'fixed', zIndex: 5, width: '100%' }}
    >
      <div className='menu__logo'>
        <Link
          onClick={() =>
            history.push(`/charts/${user.userData.username}/total`)
          }
        >
          <img src={logo} alt={logo} />
        </Link>
      </div>
      <div className='menu__container'>
        <div className='menu_left'>
          <LeftMenu mode='horizontal' />
        </div>
        <div className='menu_rigth'>
          <RightMenu mode='horizontal' />
        </div>
        <Button
          className='menu__mobile-button'
          type='primary'
          onClick={showDrawer}
        >
          <Icon type='align-right' />
        </Button>
        <Drawer
          title='<Menu>'
          placement='right'
          className='menu_drawer'
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <LeftMenu mode='inline' />
          <RightMenu mode='inline' />
        </Drawer>
      </div>
    </nav>
  );
}

export default withRouter(NavBar);
