import React from 'react';
import { Timeline, Icon } from 'antd';

export default function AppHistory() {
  return (
    <div>
      <Timeline pending='To be continued ...'>
        <Timeline.Item>
          2020-10-14 / 코스모스 차트 서비스 제안 (SF-101)
        </Timeline.Item>
        <Timeline.Item>
          2020-11-07 / 코스모스 차트 종류 및 특성 정리 문서 공유
        </Timeline.Item>
        <Timeline.Item>
          2020-11-13 / 코스모스 차트 종류 컨펌 및 요구사항 분석 회의
        </Timeline.Item>
        <Timeline.Item>2020-12-09 / 런칭 학교 선정 - 24개</Timeline.Item>
        <Timeline.Item dot={<Icon type='clock-circle-o' />}>
          2021-01-31 / 코스모스 차트 서비스 오픈 - 21개 대학으로 시작
        </Timeline.Item>
      </Timeline>
    </div>
  );
}
