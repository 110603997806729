import React from 'react';
import Auth from 'hoc/auth';
import { Route, Switch } from 'react-router-dom';

// ***** clinet chart routers start *****
// basic
import BasicIndex from 'dashboard/basic/Index';
import BasicTotal from 'dashboard/basic/total/Index';
import BasicConnection from 'dashboard/basic/connection/Index';
import BasicCourse from 'dashboard/basic/course/Index';
// test
import TestIndex from 'dashboard/test/Index';
import TestTotal from 'dashboard/test/total/Index';
import TestConnection from 'dashboard/test/connection/Index';
import TestCourse from 'dashboard/test/course/Index';
// ***** clinet chart routers end *****

function Routers() {
  return (
    <Switch>
      {/* ==== client router start ==== */}
      {/* basic start */}
      <Route exact path='/charts/basic' component={Auth(BasicIndex, true)} />
      <Route
        exact
        path='/charts/basic/total'
        component={Auth(BasicTotal, true, null, null, true, null, null)}
      />
      <Route
        exact
        path='/charts/basic/connection'
        component={Auth(BasicConnection, true, null, null, null, true, null)}
      />
      <Route
        exact
        path='/charts/basic/course'
        component={Auth(BasicCourse, true, null, null, null, null, true)}
      />
      {/* basic end */}

      {/* test start */}
      <Route exact path='/charts/test' component={Auth(TestIndex, true)} />
      <Route
        exact
        path='/charts/test/total'
        component={Auth(TestTotal, true, null, null, true, null, null)}
      />
      <Route
        exact
        path='/charts/test/connection'
        component={Auth(TestConnection, true, null, null, null, true, null)}
      />
      <Route
        exact
        path='/charts/test/course'
        component={Auth(TestCourse, true, null, null, null, null, true)}
      />
      {/* test end */}
      {/* ==== client router end  ==== */}
    </Switch>
  );
}

export default Routers;
