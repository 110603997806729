import React, { Component } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import bannerImg1 from './banner1.jpg';
import bannerImg2 from './banner2.jpg';
import bannerImg3 from './banner3.jpg';

export default class Banner extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 100,
      autoplaySpeed: 5000,
      cssEase: 'linear',
    };

    const banner1 = {
      width: '100%',
      height: '182px',
      backgroundImage: `url(${bannerImg1})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '16px',
      paddingTop: '20px',
      paddingLeft: '30px',
      paddingRight: '30px',
      textAlign: 'left',
      verticalAlign: 'middle',
    };
    const banner2 = {
      width: '100%',
      height: '182px',
      backgroundImage: `url(${bannerImg2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: '#333',
      fontWeight: 'bold',
      fontSize: '16px',
      paddingTop: '20px',
      paddingLeft: '30px',
      paddingRight: '30px',
      textAlign: 'center',
    };
    const banner3 = {
      width: '100%',
      height: '182px',
      backgroundImage: `url(${bannerImg3})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '16px',
      paddingTop: '20px',
      paddingLeft: '30px',
      paddingRight: '30px',
      textAlign: 'right',
    };
    // const banner4 = {
    //   width: '100%',
    //   height: '160px',
    //   backgroundColor: '#55C7B5',
    // };

    return (
      <div>
        <Slider {...settings}>
          <div>
            <a
              href='https://www.coursemos.kr/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div style={banner1}>
                코스모스 차트 서비스에 <br />
                오신 것을 환영합니다.
              </div>
            </a>
          </div>

          <div>
            <a
              href='https://www.coursemos.kr/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div style={banner2}>
                코스모스의 새로운 기능이 <br />
                업데이트 되었습니다.
              </div>
            </a>
          </div>

          <div>
            <a
              href='https://www.coursemos.kr/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div style={banner3}>
                여러분과 언제나 함께하는 <br />
                '코스모스'가 되겠습니다.
              </div>
            </a>
          </div>
        </Slider>
      </div>
    );
  }
}
