/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Menu, Icon, Card } from 'antd';
import axios from 'axios';
import { USER_SERVER } from '../../../Config';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

function RightMenu(props) {
  const user = useSelector((state) => state.user);

  const logoutHandler = () => {
    axios.get(`${USER_SERVER}/logout`).then((response) => {
      if (response.status === 200) {
        props.history.push('/login');
      } else {
        alert('로그아웃에 실패했습니다.');
      }
    });
  };

  if (user.userData && user.userData.isAuth && !user.userData.isObserver) {
    return (
      <Menu mode={props.mode}>
        {/* <Menu.Item key='user'>
          <a>
            <Icon type='user' />
            반갑습니다. {user.userData.client}님
          </a>
        </Menu.Item> */}

        <Menu.Item key='logout'>
          <a onClick={logoutHandler}>
            <Icon type='logout' />
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );
  } else if (user.userData && user.userData.isAdmin === true) {
    return (
      <Menu mode={props.mode}>
        <Menu.Item key='app'>
          <a href='/register'>
            <Icon type='user-add' />
            Register
          </a>
        </Menu.Item>
        <Menu.Item key='logout'>
          <a onClick={logoutHandler}>
            <Icon type='logout' />
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );
  } else {
    return (
      <Menu mode={props.mode}>
        <Menu.Item key='mail'>
          <a href='/login'>Login</a>
        </Menu.Item>
      </Menu>
    );
  }
}
export default withRouter(RightMenu);
