import React from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { registerUser } from '../../../_actions/user_actions';
import { useDispatch } from 'react-redux';

// NavBar, Footer
import NavBar from '../../../components/views/NavBar/NavBar';
import Footer from '../../../components/views/Footer/Footer';

const { Title } = Typography;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function RegisterPage(props) {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        confirmPassword: '',
        role: '',
        email: '',
        phone: '',
        client: '',
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string().required('사용자ID 항목 필수'),
        password: Yup.string()
          .min(6, '패스워드 최소 6자')
          .required('패스워드 항목 필수'),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref('password'), null], '패스워드 불일치')
          .required('패스워드 확인 항목 필수'),
        role: Yup.number(),
        email: Yup.string(),
        phone: Yup.string(),
        client: Yup.string().required('고객이름 항목 필수'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          let dataToSubmit = {
            username: values.username,
            password: values.password,
            email: values.email,
            phone: values.phone,
            client: values.client,
            role: values.role,
          };

          dispatch(registerUser(dataToSubmit)).then((response) => {
            if (response.payload.success) {
              props.history.push('/login');
            } else {
              alert(response.payload.err.errmsg);
            }
          });

          setSubmitting(false);
        }, 500);
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <div>
            <NavBar />
            <div className='app' style={{ height: 'calc(100vh - 80px)' }}>
              <Title level={2} style={{ color: '#777' }}>
                Register
              </Title>
              <div>&nbsp;</div>
              <Form
                style={{ minWidth: '350px' }}
                {...formItemLayout}
                onSubmit={handleSubmit}
              >
                <Form.Item required label='사용자ID'>
                  <Input
                    id='username'
                    placeholder='사용자ID 입력'
                    type='text'
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.username && touched.username
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.username && touched.username && (
                    <div className='input-feedback'>{errors.username}</div>
                  )}
                </Form.Item>

                <Form.Item
                  required
                  label='패스워드'
                  hasFeedback
                  validateStatus={
                    errors.password && touched.password ? 'error' : 'success'
                  }
                >
                  <Input
                    id='password'
                    placeholder='패스워드 입력 (최소 6자)'
                    type='password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.password && touched.password
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.password && touched.password && (
                    <div className='input-feedback'>{errors.password}</div>
                  )}
                </Form.Item>

                <Form.Item required label='패스워드 확인' hasFeedback>
                  <Input
                    id='confirmPassword'
                    placeholder='위와 동일한 패스워드 입력'
                    type='password'
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <div className='input-feedback'>
                      {errors.confirmPassword}
                    </div>
                  )}
                </Form.Item>

                <Form.Item label='권한'>
                  <Input
                    id='role'
                    placeholder='권한 숫자 입력 (기본값 : 0)'
                    type='number'
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.role && touched.role
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.role && touched.role && (
                    <div className='input-feedback'>{errors.role}</div>
                  )}
                </Form.Item>

                <Form.Item label='전화번호'>
                  <Input
                    id='phone'
                    placeholder='전화번호 입력'
                    type='text'
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.phone && touched.phone
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.phone && touched.phone && (
                    <div className='input-feedback'>{errors.phone}</div>
                  )}
                </Form.Item>

                <Form.Item label='이메일'>
                  <Input
                    id='email'
                    placeholder='이메일 입력'
                    type='text'
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.email && touched.email
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.email && touched.email && (
                    <div className='input-feedback'>{errors.email}</div>
                  )}
                </Form.Item>

                <Form.Item required label='고객이름'>
                  <Input
                    id='client'
                    placeholder='고객이름 입력'
                    type='text'
                    value={values.client}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.client && touched.client
                        ? 'text-input error'
                        : 'text-input'
                    }
                  />
                  {errors.client && touched.client && (
                    <div className='input-feedback'>{errors.client}</div>
                  )}
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                  <Button
                    onClick={handleSubmit}
                    type='primary'
                    disabled={isSubmitting}
                    style={{ minWidth: '100%' }}
                  >
                    등록하기
                  </Button>
                </Form.Item>
              </Form>
            </div>
            <Footer />
          </div>
        );
      }}
    </Formik>
  );
}

export default RegisterPage;
