// client 코드별 dashboard 초기 화면
import React from 'react';

// json data import
// import { json } from './Config';

// Config style import
import { backgroundStyle } from './Config';

export default function Index() {
  return <div style={backgroundStyle}>Dashboard</div>;
}
