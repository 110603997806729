import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const data = {
  type: 'bar',
  json: {
    data1: [
      json[json.length - 6].course.total,
      json[json.length - 5].course.total,
      json[json.length - 4].course.total,
      json[json.length - 3].course.total,
      json[json.length - 2].course.total,
      json[json.length - 1].course.total,
    ],
    data2: [
      json[json.length - 6].course.open,
      json[json.length - 5].course.open,
      json[json.length - 4].course.open,
      json[json.length - 3].course.open,
      json[json.length - 2].course.open,
      json[json.length - 1].course.open,
    ],
  },
  names: {
    data1: '전체강좌(개)',
    data2: '강좌개설(개)',
  },
  labels: {
    // colors: '#333',
    show: true,
  },
  // custom colors
  colors: {
    data1: '#38A0FB',
    data2: '#54E7A6',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
  },
};

const bar = {
  padding: 4,
};

const axis = {
  x: {
    type: 'category',
    categories: [
      json[json.length - 6].category,
      json[json.length - 5].category,
      json[json.length - 4].category,
      json[json.length - 3].category,
      json[json.length - 2].category,
      json[json.length - 1].category,
    ],
  },
};

export default function Chart() {
  return <BillboardChart data={data} axis={axis} bar={bar} />;
}
