import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const newData1 = [
  (json[json.length - 6].core_activity.movie /
    (json[json.length - 6].core_activity.movie +
      json[json.length - 6].core_activity.assignment +
      json[json.length - 6].core_activity.poll_quiz)) *
    100,
  (json[json.length - 5].core_activity.movie /
    (json[json.length - 5].core_activity.movie +
      json[json.length - 5].core_activity.assignment +
      json[json.length - 5].core_activity.poll_quiz)) *
    100,
  (json[json.length - 4].core_activity.movie /
    (json[json.length - 4].core_activity.movie +
      json[json.length - 4].core_activity.assignment +
      json[json.length - 4].core_activity.poll_quiz)) *
    100,
  (json[json.length - 3].core_activity.movie /
    (json[json.length - 3].core_activity.movie +
      json[json.length - 3].core_activity.assignment +
      json[json.length - 3].core_activity.poll_quiz)) *
    100,
  (json[json.length - 2].core_activity.movie /
    (json[json.length - 2].core_activity.movie +
      json[json.length - 2].core_activity.assignment +
      json[json.length - 2].core_activity.poll_quiz)) *
    100,
  (json[json.length - 1].core_activity.movie /
    (json[json.length - 1].core_activity.movie +
      json[json.length - 1].core_activity.assignment +
      json[json.length - 1].core_activity.poll_quiz)) *
    100,
];

const newData2 = [
  (json[json.length - 6].core_activity.assignment /
    (json[json.length - 6].core_activity.movie +
      json[json.length - 6].core_activity.assignment +
      json[json.length - 6].core_activity.poll_quiz)) *
    100,
  (json[json.length - 5].core_activity.assignment /
    (json[json.length - 5].core_activity.movie +
      json[json.length - 5].core_activity.assignment +
      json[json.length - 5].core_activity.poll_quiz)) *
    100,
  (json[json.length - 4].core_activity.assignment /
    (json[json.length - 4].core_activity.movie +
      json[json.length - 4].core_activity.assignment +
      json[json.length - 4].core_activity.poll_quiz)) *
    100,
  (json[json.length - 3].core_activity.assignment /
    (json[json.length - 3].core_activity.movie +
      json[json.length - 3].core_activity.assignment +
      json[json.length - 3].core_activity.poll_quiz)) *
    100,
  (json[json.length - 2].core_activity.assignment /
    (json[json.length - 2].core_activity.movie +
      json[json.length - 2].core_activity.assignment +
      json[json.length - 2].core_activity.poll_quiz)) *
    100,
  (json[json.length - 1].core_activity.assignment /
    (json[json.length - 1].core_activity.movie +
      json[json.length - 1].core_activity.assignment +
      json[json.length - 1].core_activity.poll_quiz)) *
    100,
];

const newData3 = [
  (json[json.length - 6].core_activity.poll_quiz /
    (json[json.length - 6].core_activity.movie +
      json[json.length - 6].core_activity.assignment +
      json[json.length - 6].core_activity.poll_quiz)) *
    100,
  (json[json.length - 5].core_activity.poll_quiz /
    (json[json.length - 5].core_activity.movie +
      json[json.length - 5].core_activity.assignment +
      json[json.length - 5].core_activity.poll_quiz)) *
    100,
  (json[json.length - 4].core_activity.poll_quiz /
    (json[json.length - 4].core_activity.movie +
      json[json.length - 4].core_activity.assignment +
      json[json.length - 4].core_activity.poll_quiz)) *
    100,
  (json[json.length - 3].core_activity.poll_quiz /
    (json[json.length - 3].core_activity.movie +
      json[json.length - 3].core_activity.assignment +
      json[json.length - 3].core_activity.poll_quiz)) *
    100,
  (json[json.length - 2].core_activity.poll_quiz /
    (json[json.length - 2].core_activity.movie +
      json[json.length - 2].core_activity.assignment +
      json[json.length - 2].core_activity.poll_quiz)) *
    100,
  (json[json.length - 1].core_activity.poll_quiz /
    (json[json.length - 1].core_activity.movie +
      json[json.length - 1].core_activity.assignment +
      json[json.length - 1].core_activity.poll_quiz)) *
    100,
];

const preData1 = [
  newData1[0].toFixed(1),
  newData1[1].toFixed(1),
  newData1[2].toFixed(1),
  newData1[3].toFixed(1),
  newData1[4].toFixed(1),
  newData1[5].toFixed(1),
];
const preData2 = [
  newData2[0].toFixed(1),
  newData2[1].toFixed(1),
  newData2[2].toFixed(1),
  newData2[3].toFixed(1),
  newData2[4].toFixed(1),
  newData2[5].toFixed(1),
];
const preData3 = [
  newData3[0].toFixed(1),
  newData3[1].toFixed(1),
  newData3[2].toFixed(1),
  newData3[3].toFixed(1),
  newData3[4].toFixed(1),
  newData3[5].toFixed(1),
];

const data = {
  type: 'bar',
  json: {
    data1: [
      preData1[0],
      preData1[1],
      preData1[2],
      preData1[3],
      preData1[4],
      preData1[5],
    ],
    data2: [
      preData2[0],
      preData2[1],
      preData2[2],
      preData2[3],
      preData2[4],
      preData2[5],
    ],
    data3: [
      preData3[0],
      preData3[1],
      preData3[2],
      preData3[3],
      preData3[4],
      preData3[5],
    ],
  },
  names: {
    data1: '영상',
    data2: '과제',
    data3: '설문/퀴즈',
  },
  groups: [['data1', 'data2', 'data3']],
  labels: {
    colors: {
      data1: '#fff',
      data2: '',
      data3: '#fff',
    },
    show: true,
  },
  // custom colors
  colors: {
    data1: '#38A0FB',
    data2: '#54E7A6',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
  },
};

const axis = {
  x: {
    type: 'category',
    categories: [
      json[json.length - 6].category,
      json[json.length - 5].category,
      json[json.length - 4].category,
      json[json.length - 3].category,
      json[json.length - 2].category,
      json[json.length - 1].category,
    ],
  },
};

export default function Chart() {
  return <BillboardChart data={data} axis={axis} />;
}
