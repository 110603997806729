import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const data = {
  type: 'bar',
  json: {
    data1: [
      json[json.length - 6].activity_performance.assignment,
      json[json.length - 5].activity_performance.assignment,
      json[json.length - 4].activity_performance.assignment,
      json[json.length - 3].activity_performance.assignment,
      json[json.length - 2].activity_performance.assignment,
      json[json.length - 1].activity_performance.assignment,
    ],
    data2: [
      json[json.length - 6].activity_performance.poll,
      json[json.length - 5].activity_performance.poll,
      json[json.length - 4].activity_performance.poll,
      json[json.length - 3].activity_performance.poll,
      json[json.length - 2].activity_performance.poll,
      json[json.length - 1].activity_performance.poll,
    ],
    data3: [
      json[json.length - 6].activity_performance.quiz,
      json[json.length - 5].activity_performance.quiz,
      json[json.length - 4].activity_performance.quiz,
      json[json.length - 3].activity_performance.quiz,
      json[json.length - 2].activity_performance.quiz,
      json[json.length - 1].activity_performance.quiz,
    ],
    data4: [
      json[json.length - 6].activity_performance.attendance,
      json[json.length - 5].activity_performance.attendance,
      json[json.length - 4].activity_performance.attendance,
      json[json.length - 3].activity_performance.attendance,
      json[json.length - 2].activity_performance.attendance,
      json[json.length - 1].activity_performance.attendance,
    ],
    data5: [
      json[json.length - 6].activity_performance.grade,
      json[json.length - 5].activity_performance.grade,
      json[json.length - 4].activity_performance.grade,
      json[json.length - 3].activity_performance.grade,
      json[json.length - 2].activity_performance.grade,
      json[json.length - 1].activity_performance.grade,
    ],
  },
  names: {
    data1: '과제',
    data2: '설문조사',
    data3: '퀴즈',
    data4: '출석부',
    data5: '성적부',
  },
  labels: {
    // colors: '#333',
    show: true,
  },
  // custom colors
  colors: {
    data1: '#38A0FB',
    data2: '#54E7A6',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
    data7: '#FF66CC',
  },
};

const bar = {
  padding: 3,
};

const axis = {
  // rotated: true,
  x: {
    type: 'category',
    categories: [
      json[json.length - 6].category,
      json[json.length - 5].category,
      json[json.length - 4].category,
      json[json.length - 3].category,
      json[json.length - 2].category,
      json[json.length - 1].category,
    ],
  },
};

const grid = {
  x: {
    show: true,
  },
  y: {
    show: true,
  },
};

export default function Chart() {
  return <BillboardChart data={data} axis={axis} grid={grid} bar={bar} />;
}
