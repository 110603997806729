// json 파일을 불러온 후 다시 외부에서 사용할 수 있도록 설정
import jsonData from './test.json';
export const json = jsonData;

// TitleBox Style export
export const subTitleCardStyle = {
  background: '#f3f3f3',
  justifyContent: 'center',
};
export const divStyle = {
  justifyContent: 'center',
  display: 'flex',
};
export const imgStyle = {
  height: '30px',
  marginBottom: '10px',
  marginRight: '10px',
};
export const textStyle = {
  color: '#74CBFC',
  fontSize: '28px',
};

// main Style export
export const mainStyle = {};

// Dashboard Style export
export const gutter = [2, 2];
export const backgroundStyle = {
  background: '#fff',
  padding: '80px 30px 20px 30px',
};
export const titleCardStyle = {
  background: '#d9d9d9',
  padding: '0px',
  margin: '5px',
};
export const chartStyle = {
  padding: '0px',
  margin: '5px',
};
export const headStyle = {
  // coursemos key color
  background: '#74CBFC',
  color: '#fff',
};
export const headStyle2 = {
  background: '#EF8360',
  color: '#fff',
};
export const headStyle3 = {
  background: '#55C7B5',
  color: '#fff',
};
export const headStyle4 = {
  background: '#FFB838',
  color: '#fff',
};
export const headStyle5 = {
  background: '#557288',
  color: '#fff',
};

// Banner Style
export const bannerStyle = {
  padding: '0px',
  margin: '5px',
};

// Total Box Style
export const totalBoxStyle = {
  color: '#777',
};
