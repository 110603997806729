import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { loginUser } from '../../../_actions/user_actions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Icon, Input, Button, Typography } from 'antd';
import { useDispatch } from 'react-redux';

import mainImage from '../../images/coursemos-vertical-full.png';

const { Title } = Typography;

// bgImag random generate
const IMG_NUMBER = 110;
const imgPath = '../../bgImages/';
const imgExt = '.jpg';
const imgNumber = Math.floor(Math.random() * IMG_NUMBER) + 1;
// const bgImage = imgPath + imgNumber + imgExt;
// warning! images folder must have exist in public folder
//

const backgroundImageStyle = {
  width: '100%',
  height: '100vh',
  backgroundImage: `url(${imgPath + imgNumber + imgExt})`,
  // backgroundImage:
  //   'url(https://source.unsplash.com/random/1920x1080?nature,city,sky,cloud,sea)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};
const divStyle = {
  border: '2px solid #dedede',
  backgroundColor: '#fff',
  opacity: 0.93,
  padding: '25px 30px 0 30px',
};
const copyrights = {
  position: 'fixed',
  right: '35px',
  bottom: '35px',
  fontSize: '13px',
  color: '#dfdfdf',
};
const titleTextStyle = {
  level: 2,
  color: '#777',
  textAlign: 'center',
};
const logoImageStyle = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '180px',
};
const formStyle = {
  width: '240px',
};

function LoginPage(props) {
  const dispatch = useDispatch();
  const [formErrorMessage, setFormErrorMessage] = useState('');

  return (
    <div style={backgroundImageStyle}>
      <Formik
        initialValues={{
          email: '',
          username: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required('사용자ID 항목 필수'),
          password: Yup.string()
            .min(6, '패스워드 최소 6자')
            .required('패스워드 항목 필수'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            let dataToSubmit = {
              username: values.username,
              password: values.password,
            };

            dispatch(loginUser(dataToSubmit))
              .then((response) => {
                if (response.payload.loginSuccess) {
                  if (response.payload.isAdmin) {
                    props.history.push('/charts/adm');
                  } else {
                    if (response.payload.isObserver) {
                      props.history.push('/charts/obs');
                    } else {
                      props.history.push(`/charts/${values.username}/total`);
                    }
                  }
                } else {
                  setFormErrorMessage('아이디와 패스워드를 다시 확인하세요.');
                }
              })
              .catch((err) => {
                setFormErrorMessage('아이디와 패스워드를 다시 확인하세요.');
                setTimeout(() => {
                  setFormErrorMessage('');
                }, 3000);
              });
            setSubmitting(false);
          }, 500);
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <div className='app'>
              <div style={divStyle}>
                <img src={mainImage} alt={mainImage} style={logoImageStyle} />
                <div>&nbsp;</div>
                <Title level={titleTextStyle.level} style={titleTextStyle}>
                  Login
                </Title>
                <div>&nbsp;</div>
                <Form onSubmit={handleSubmit} style={formStyle}>
                  <Form.Item required>
                    <Input
                      id='username'
                      prefix={
                        <Icon
                          type='user'
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder='사용자ID 입력'
                      type='text'
                      value={values.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.username && touched.username
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    {errors.username && touched.username && (
                      <div className='input-feedback'>{errors.username}</div>
                    )}
                  </Form.Item>

                  <Form.Item required>
                    <Input
                      id='password'
                      prefix={
                        <Icon
                          type='lock'
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder='패스워드 입력'
                      type='password'
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.password && touched.password
                          ? 'text-input error'
                          : 'text-input'
                      }
                    />
                    {errors.password && touched.password && (
                      <div className='input-feedback'>{errors.password}</div>
                    )}
                  </Form.Item>

                  {formErrorMessage && (
                    <label>
                      <p
                        style={{
                          color: '#ff0000bf',
                          fontSize: '0.7rem',
                          border: '1px solid',
                          padding: '1rem',
                          borderRadius: '10px',
                        }}
                      >
                        {formErrorMessage}
                      </p>
                    </label>
                  )}

                  <Form.Item>
                    <div>
                      <Button
                        type='primary'
                        htmlType='submit'
                        className='login-form-button'
                        style={{ minWidth: '100%' }}
                        disabled={isSubmitting}
                        onSubmit={handleSubmit}
                      >
                        로그인
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
              <div style={copyrights}>
                Powered by{' '}
                <a href='https://www.coursemos.kr' target='_blank'>
                  COURSEMOS
                </a>{' '}
                DATA LAB
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default withRouter(LoginPage);
