import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const newData = [
  json[json.length - 6].login * 0.01,
  json[json.length - 5].login * 0.01,
  json[json.length - 4].login * 0.01,
  json[json.length - 3].login * 0.01,
  json[json.length - 2].login * 0.01,
  json[json.length - 1].login * 0.01,
];

const data = {
  types: {
    data1: 'line',
    data2: 'line',
  },
  json: {
    data1: newData,

    data2: [
      json[json.length - 6].enrollment,
      json[json.length - 5].enrollment,
      json[json.length - 4].enrollment,
      json[json.length - 3].enrollment,
      json[json.length - 2].enrollment,
      json[json.length - 1].enrollment,
    ],
  },
  names: {
    data1: '로그인(백건)',
    data2: '수강신청(건)',
  },
  labels: {
    // colors: '#333',
    show: true,
  },
  // custom colors
  colors: {
    // data1: '#38A0FB',
    // data2: '#54E7A6',
    data1: '#8C76D7',
    data2: '#F66178',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
  },
};

const axis = {
  x: {
    type: 'category',
    categories: [
      json[json.length - 6].category,
      json[json.length - 5].category,
      json[json.length - 4].category,
      json[json.length - 3].category,
      json[json.length - 2].category,
      json[json.length - 1].category,
    ],
  },
};

export default function Chart() {
  return <BillboardChart data={data} axis={axis} />;
}
