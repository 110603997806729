// client 코드별 dashboard 초기 화면
import React from 'react';
import { Row, Col, Divider } from 'antd';

// style import
import { textStyle } from './Style';

export default function TitleBox(props) {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Divider orientation={'right'}>
            <span style={textStyle}>{props.subTitle} Data</span>
          </Divider>
        </Col>
      </Row>
    </div>
  );
}
