import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const newData1 = [
  (
    (json[json.length - 6].course.open / json[json.length - 6].course.total) *
    100
  ).toFixed(1),
  (
    (json[json.length - 5].course.open / json[json.length - 5].course.total) *
    100
  ).toFixed(1),
  (
    (json[json.length - 4].course.open / json[json.length - 4].course.total) *
    100
  ).toFixed(1),
  (
    (json[json.length - 3].course.open / json[json.length - 3].course.total) *
    100
  ).toFixed(1),
  (
    (json[json.length - 2].course.open / json[json.length - 2].course.total) *
    100
  ).toFixed(1),
  (
    (json[json.length - 1].course.open / json[json.length - 1].course.total) *
    100
  ).toFixed(1),
];

const newData2 = [
  (
    (json[json.length - 6].course.use / json[json.length - 6].course.total) *
    100
  ).toFixed(1),
  (
    (json[json.length - 5].course.use / json[json.length - 5].course.total) *
    100
  ).toFixed(1),
  (
    (json[json.length - 4].course.use / json[json.length - 4].course.total) *
    100
  ).toFixed(1),
  (
    (json[json.length - 3].course.use / json[json.length - 3].course.total) *
    100
  ).toFixed(1),
  (
    (json[json.length - 2].course.use / json[json.length - 2].course.total) *
    100
  ).toFixed(1),
  (
    (json[json.length - 1].course.use / json[json.length - 1].course.total) *
    100
  ).toFixed(1),
];

const data = {
  type: 'bar',
  json: {
    data1: [
      -newData1[0],
      -newData1[1],
      -newData1[2],
      -newData1[3],
      -newData1[4],
      -newData1[5],
    ],
    data2: [
      newData2[0],
      newData2[1],
      newData2[2],
      newData2[3],
      newData2[4],
      newData2[5],
    ],
  },
  groups: [['data1', 'data2']],
  names: {
    data1: '강좌개설 비율(%)',
    data2: '강좌사용 비율(%)',
  },
  labels: {
    // colors: '#333',
    show: true,
    format: function (v, id) {
      return Math.abs(v);
    },
  },
  // custom colors
  colors: {
    // data1: '#38A0FB',
    // data2: '#54E7A6',
    data1: '#8C76D7',
    data2: '#FBBC44',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
  },
};

const axis = {
  rotated: true,
  x: {
    type: 'category',
    categories: [
      json[json.length - 6].category,
      json[json.length - 5].category,
      json[json.length - 4].category,
      json[json.length - 3].category,
      json[json.length - 2].category,
      json[json.length - 1].category,
    ],
  },
  y: {
    tick: {
      format: function (v, id) {
        return Math.abs(v);
      },
    },
  },
};

const grid = {
  y: {
    show: true,
    lines: [
      {
        values: 0,
        class: 'base-line',
      },
    ],
  },
};

const tooltip = {
  format: {
    valuse: function (v, id) {
      return Math.abs(v);
    },
  },
};

export default function Chart() {
  return (
    <BillboardChart data={data} axis={axis} grid={grid} tooltip={tooltip} />
  );
}
