// client 코드별 dashboard 초기 화면
import React, { useState, initialState, useEffect } from 'react';

// json data import
import { json } from './Config';

// Config style import
import { backgroundStyle } from './Config';

export default function Index({ history }) {
  const [Loading, setLoading] = useState([]);
  const [Charts, setCharts] = useState(initialState);

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      setCharts(json);
      setLoading(false);
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return <div style={backgroundStyle}>Dashboard</div>;
}
