// TitleBox Style
export const subTitleCardStyle = {
  background: '#f3f3f3',
  justifyContent: 'center',
};
export const divStyle = {
  justifyContent: 'center',
  display: 'flex',
};
export const imgStyle = {
  height: '30px',
  marginBottom: '10px',
  marginRight: '10px',
};
export const textStyle = {
  color: '#74CBFC',
  fontSize: '28px',
};

// Dashboard Style
export const gutter = [2, 2];
export const backgroundStyle = {
  background: '#fff',
  padding: '80px 30px 20px 30px',
};
export const titleCardStyle = {
  background: '#d9d9d9',
  padding: '0px',
  margin: '5px',
};
export const chartStyle = {
  padding: '0px',
  margin: '5px',
};
export const headStyle = {
  // coursemos key color
  background: '#74CBFC',
  color: '#fff',
};
