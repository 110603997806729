/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { auth } from '_actions/user_actions';
import { useSelector, useDispatch } from 'react-redux';

export default function (
  SpecificComponent,
  option,
  adminRoute = null,
  observationRoute = null,
  protectedChart = false,
  protectedChart2 = false,
  protectedChart3 = false
) {
  function AuthenticationCheck(props) {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const currentTabUrl = window.location.pathname;

    useEffect(() => {
      //To know my current status, send Auth request
      dispatch(auth()).then((response) => {
        //Not Loggined in Status
        if (!response.payload.isAuth) {
          if (option) {
            props.history.push('/login');
          }
          //Loggined in Status
        } else {
          //supposed to be Admin page, but not admin person wants to go inside
          if (adminRoute && !response.payload.isAdmin) {
            props.history.push('/');
          }
          //Logged in Status, but Try to go into log in page
          else {
            if (option === false) {
              props.history.push('/');
            }
            //Monitored Route
            else {
              if (observationRoute && !response.payload.isObserver) {
                props.history.push('/');
              }
              //Admin in monitored route
              else {
                if (observationRoute && response.payload.isAdmin) {
                  return null;
                }

                //protected
                else {
                  if (
                    protectedChart && !response.payload.isObserver && 
                    currentTabUrl !==
                      `/charts/${response.payload.username}/total`
                  ) {
                    props.history.push(`/`);
                  } else {
                    if (
                      protectedChart2 && !response.payload.isObserver &&
                      currentTabUrl !==
                        `/charts/${response.payload.username}/connection`
                    ) {
                      props.history.push(`/`);
                    } else {
                      if (
                        protectedChart3 && !response.payload.isObserver &&
                        currentTabUrl !==
                          `/charts/${response.payload.username}/course`
                      ) {
                        props.history.push(`/`);
                      } 
                    }
                  }
                }
              }
            }
          }
        }
      });
    }, []);

    return <SpecificComponent {...props} user={user} />;
  }
  return AuthenticationCheck;
}
