import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const data = {
  type: 'radar',
  json: {
    data1: [
      json[json.length - 6].device.desktop_ratio,
      json[json.length - 5].device.desktop_ratio,
      json[json.length - 4].device.desktop_ratio,
      json[json.length - 3].device.desktop_ratio,
      json[json.length - 2].device.desktop_ratio,
      json[json.length - 1].device.desktop_ratio,
    ],
    data2: [
      json[json.length - 6].device.mobile_ratio,
      json[json.length - 5].device.mobile_ratio,
      json[json.length - 4].device.mobile_ratio,
      json[json.length - 3].device.mobile_ratio,
      json[json.length - 2].device.mobile_ratio,
      json[json.length - 1].device.mobile_ratio,
    ],
    data3: [
      json[json.length - 6].device.tablet_ratio,
      json[json.length - 5].device.tablet_ratio,
      json[json.length - 4].device.tablet_ratio,
      json[json.length - 3].device.tablet_ratio,
      json[json.length - 2].device.tablet_ratio,
      json[json.length - 1].device.tablet_ratio,
    ],
  },
  names: {
    data1: '데스크톱',
    data2: '모바일',
    data3: '태블릿',
  },
  // custom colors
  colors: {
    data1: '#38A0FB',
    data2: '#54E7A6',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
  },
};

const axis = {
  x: {
    type: 'category',
    categories: [
      json[json.length - 6].category,
      json[json.length - 5].category,
      json[json.length - 4].category,
      json[json.length - 3].category,
      json[json.length - 2].category,
      json[json.length - 1].category,
    ],
  },
};

export default function Chart() {
  return <BillboardChart data={data} axis={axis} />;
}
