import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const data = {
  type: 'radar',
  json: {
    data1: [
      json[json.length - 6].funnel.direct_ratio,
      json[json.length - 5].funnel.direct_ratio,
      json[json.length - 4].funnel.direct_ratio,
      json[json.length - 3].funnel.direct_ratio,
      json[json.length - 2].funnel.direct_ratio,
      json[json.length - 1].funnel.direct_ratio,
    ],
    data2: [
      json[json.length - 6].funnel.search_ratio,
      json[json.length - 5].funnel.search_ratio,
      json[json.length - 4].funnel.search_ratio,
      json[json.length - 3].funnel.search_ratio,
      json[json.length - 2].funnel.search_ratio,
      json[json.length - 1].funnel.search_ratio,
    ],
    data3: [
      json[json.length - 6].funnel.recommend_ratio,
      json[json.length - 5].funnel.recommend_ratio,
      json[json.length - 4].funnel.recommend_ratio,
      json[json.length - 3].funnel.recommend_ratio,
      json[json.length - 2].funnel.recommend_ratio,
      json[json.length - 1].funnel.recommend_ratio,
    ],
    data4: [
      json[json.length - 6].funnel.social_ratio,
      json[json.length - 5].funnel.social_ratio,
      json[json.length - 4].funnel.social_ratio,
      json[json.length - 3].funnel.social_ratio,
      json[json.length - 2].funnel.social_ratio,
      json[json.length - 1].funnel.social_ratio,
    ],
  },
  names: {
    data1: '직접 유입',
    data2: '검색 유입',
    data3: '추천 유입',
    data4: '소셜 유입',
  },
  // custom colors
  colors: {
    data1: '#38A0FB',
    data2: '#54E7A6',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
  },
};

const axis = {
  x: {
    type: 'category',
    categories: [
      json[json.length - 6].category,
      json[json.length - 5].category,
      json[json.length - 4].category,
      json[json.length - 3].category,
      json[json.length - 2].category,
      json[json.length - 1].category,
    ],
  },
};

export default function Chart() {
  return <BillboardChart data={data} axis={axis} />;
}
