import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const data = {
  type: 'area-spline',
  json: {
    data1: [
      json[json.length - 6].location.gangwon,
      json[json.length - 6].location.gyeonggi,
      json[json.length - 6].location.gyeongnam,
      json[json.length - 6].location.gyeongbuk,
      json[json.length - 6].location.gwangju,
      json[json.length - 6].location.daegu,
      json[json.length - 6].location.daejeon,
      json[json.length - 6].location.busan,
      json[json.length - 6].location.seoul,
      json[json.length - 6].location.sejong,
      json[json.length - 6].location.ulsan,
      json[json.length - 6].location.incheon,
      json[json.length - 6].location.jeollanam,
      json[json.length - 6].location.jeollabuk,
      json[json.length - 6].location.jeju,
      json[json.length - 6].location.chungnam,
      json[json.length - 6].location.chungbuk,
      json[json.length - 6].location.etc,
    ],
    data2: [
      json[json.length - 5].location.gangwon,
      json[json.length - 5].location.gyeonggi,
      json[json.length - 5].location.gyeongnam,
      json[json.length - 5].location.gyeongbuk,
      json[json.length - 5].location.gwangju,
      json[json.length - 5].location.daegu,
      json[json.length - 5].location.daejeon,
      json[json.length - 5].location.busan,
      json[json.length - 5].location.seoul,
      json[json.length - 5].location.sejong,
      json[json.length - 5].location.ulsan,
      json[json.length - 5].location.incheon,
      json[json.length - 5].location.jeollanam,
      json[json.length - 5].location.jeollabuk,
      json[json.length - 5].location.jeju,
      json[json.length - 5].location.chungnam,
      json[json.length - 5].location.chungbuk,
      json[json.length - 5].location.etc,
    ],
    data3: [
      json[json.length - 4].location.gangwon,
      json[json.length - 4].location.gyeonggi,
      json[json.length - 4].location.gyeongnam,
      json[json.length - 4].location.gyeongbuk,
      json[json.length - 4].location.gwangju,
      json[json.length - 4].location.daegu,
      json[json.length - 4].location.daejeon,
      json[json.length - 4].location.busan,
      json[json.length - 4].location.seoul,
      json[json.length - 4].location.sejong,
      json[json.length - 4].location.ulsan,
      json[json.length - 4].location.incheon,
      json[json.length - 4].location.jeollanam,
      json[json.length - 4].location.jeollabuk,
      json[json.length - 4].location.jeju,
      json[json.length - 4].location.chungnam,
      json[json.length - 4].location.chungbuk,
      json[json.length - 4].location.etc,
    ],
    data4: [
      json[json.length - 3].location.gangwon,
      json[json.length - 3].location.gyeonggi,
      json[json.length - 3].location.gyeongnam,
      json[json.length - 3].location.gyeongbuk,
      json[json.length - 3].location.gwangju,
      json[json.length - 3].location.daegu,
      json[json.length - 3].location.daejeon,
      json[json.length - 3].location.busan,
      json[json.length - 3].location.seoul,
      json[json.length - 3].location.sejong,
      json[json.length - 3].location.ulsan,
      json[json.length - 3].location.incheon,
      json[json.length - 3].location.jeollanam,
      json[json.length - 3].location.jeollabuk,
      json[json.length - 3].location.jeju,
      json[json.length - 3].location.chungnam,
      json[json.length - 3].location.chungbuk,
      json[json.length - 3].location.etc,
    ],
    data5: [
      json[json.length - 2].location.gangwon,
      json[json.length - 2].location.gyeonggi,
      json[json.length - 2].location.gyeongnam,
      json[json.length - 2].location.gyeongbuk,
      json[json.length - 2].location.gwangju,
      json[json.length - 2].location.daegu,
      json[json.length - 2].location.daejeon,
      json[json.length - 2].location.busan,
      json[json.length - 2].location.seoul,
      json[json.length - 2].location.sejong,
      json[json.length - 2].location.ulsan,
      json[json.length - 2].location.incheon,
      json[json.length - 2].location.jeollanam,
      json[json.length - 2].location.jeollabuk,
      json[json.length - 2].location.jeju,
      json[json.length - 2].location.chungnam,
      json[json.length - 2].location.chungbuk,
      json[json.length - 2].location.etc,
    ],
    data6: [
      json[json.length - 1].location.gangwon,
      json[json.length - 1].location.gyeonggi,
      json[json.length - 1].location.gyeongnam,
      json[json.length - 1].location.gyeongbuk,
      json[json.length - 1].location.gwangju,
      json[json.length - 1].location.daegu,
      json[json.length - 1].location.daejeon,
      json[json.length - 1].location.busan,
      json[json.length - 1].location.seoul,
      json[json.length - 1].location.sejong,
      json[json.length - 1].location.ulsan,
      json[json.length - 1].location.incheon,
      json[json.length - 1].location.jeollanam,
      json[json.length - 1].location.jeollabuk,
      json[json.length - 1].location.jeju,
      json[json.length - 1].location.chungnam,
      json[json.length - 1].location.chungbuk,
      json[json.length - 1].location.etc,
    ],
  },
  names: {
    data1: json[json.length - 6].category,
    data2: json[json.length - 5].category,
    data3: json[json.length - 4].category,
    data4: json[json.length - 3].category,
    data5: json[json.length - 2].category,
    data6: json[json.length - 1].category,
  },
  labels: {
    // colors: '#333',
    show: true,
  },
  // custom colors
  colors: {
    data1: '#38A0FB',
    data2: '#54E7A6',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
  },
};

const axis = {
  x: {
    type: 'category',
    categories: [
      '강원',
      '경기',
      '경남',
      '경북',
      '광주',
      '대구',
      '대전',
      '부산',
      '서울',
      '세종',
      '울산',
      '인천',
      '전남',
      '전북',
      '제주',
      '충남',
      '충북',
      '기타',
    ],
  },
};

const tooltip = {
  grouped: true,
};

export default function Chart() {
  return <BillboardChart data={data} axis={axis} tooltip={tooltip} />;
}
