import { Button, Typography } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

// NavBar, Footer
// import NavBar from '../NavBar/NavBar';
// import Footer from '../Footer/Footer';

// main img
import mainImage from '../../images/coursemos-vertical-full.png';

// Typography
const { Title } = Typography;

const divStyle = {
  display: 'block',
  marginTop: '15%',
  textAlign: 'center',
  justifyContent: 'center',
};
const textStyle = {
  level: 2,
  color: '#777',
  marginBottom: '20px',
};
const buttonStyle = {
  color: '#fff',
  background: '#74CBFC',
  border: '1px solid #74CBFC',
};
const imgStyle = {
  width: '165px',
};

function LandingPage({ history }) {
  const user = useSelector((state) => state.user);

  return (
    <div style={divStyle}>
      <img src={mainImage} alt={mainImage} style={imgStyle} />
      <div>&nbsp;</div>
      <Title level={textStyle.level} style={textStyle}>
        페이지가 없습니다.
      </Title>
      <div>&nbsp;</div>
      <Button
        style={buttonStyle}
        onClick={() => history.push(`/charts/${user.userData.username}/total`)}
      >
        Total Data 화면 이동
      </Button>
    </div>
  );
}

export default withRouter(LandingPage);
