import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Descriptions } from 'antd';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

// NavBar, Footer
import NavBar from '../../../components/views/NavBar/NavBar';
import Footer from '../../../components/views/Footer/Footer';

// style import
import { gutter, backgroundStyle, headStyle, chartStyle } from './Style';

import TitleBox from './Common';
import AppHistory from './AppHistory';

// const { SubMenu } = Menu;

function AdminTotal(props) {
  const [Users, setUsers] = useState([]);

  useEffect(() => {
    axios.post('/api/users/adminuser').then((response) => {
      if (response.data.success) {
        console.log(response.data);
        setUsers(response.data.userInfo);
      } else {
        alert('사용자 데이터 호출 실패');
      }
    });
  }, []);

  const renderCards = Users.map((user, index) => {
    return (
      <Col>
        <Descriptions bordered size='small'>
          <Descriptions.Item label='고객명'>
            <b>{user.client}</b>
          </Descriptions.Item>
          <Descriptions.Item label='사용자ID'>
            <b>{user.username}</b>
          </Descriptions.Item>
          <Descriptions.Item label='패스워드'>{user.phone}</Descriptions.Item>
          <Descriptions.Item label='차트목록' sapn={3}>
            <a
              onClick={() =>
                props.history.push(`/charts/${user.username}/total`)
              }
            >
              Total
            </a>{' '}
            |{' '}
            <a
              onClick={() =>
                props.history.push(`/charts/${user.username}/connection`)
              }
            >
              Connection
            </a>{' '}
            |{' '}
            <a
              onClick={() =>
                props.history.push(`/charts/${user.username}/course`)
              }
            >
              Course
            </a>
          </Descriptions.Item>
        </Descriptions>
        <br />
        {/* <Descriptions
          xs={24}
          color={'#ccc'}
          key={index}
          size='middle'
          style={textStyle}
        >
          <Descriptions.Item>
            <Menu style={{ width: 250 }} mode='vertical'>
              <SubMenu key='sub1' title={user.username}>
                <Menu.ItemGroup>
                  <Menu.Item key='1'>
                    <a
                      onClick={() =>
                        props.history.push(`/charts/${user.username}/total`)
                      }
                    >
                      Total
                    </a>
                  </Menu.Item>
                  <Menu.Item key='2'>
                    <a
                      onClick={() =>
                        props.history.push(
                          `/charts/${user.username}/connection`
                        )
                      }
                    >
                      Connection
                    </a>
                  </Menu.Item>
                  <Menu.Item key='2'>
                    <a
                      onClick={() =>
                        props.history.push(`/charts/${user.username}/course`)
                      }
                    >
                      Course
                    </a>
                  </Menu.Item>
                </Menu.ItemGroup>
              </SubMenu>
            </Menu>
          </Descriptions.Item>
          <Descriptions.Item>{user.email}</Descriptions.Item>
          <Descriptions.Item>{user.phone}</Descriptions.Item>
          <Descriptions.Item>{user.client}</Descriptions.Item>
        </Descriptions> */}
      </Col>
    );
  });

  return (
    <div>
      <NavBar />
      <div style={backgroundStyle}>
        <TitleBox subTitle='Admin' />

        <Row gutter={gutter}>
          <Col sm={24} md={16}>
            <Card style={chartStyle} headStyle={headStyle} title='고객 목록'>
              <Col>
                <Descriptions
                  xs={24}
                  color={'#ccc'}
                  size='small'
                  style={{ borderBottom: '1px solid #ccc' }}
                >
                  {/* <Descriptions.Item>User ID</Descriptions.Item>
                  <Descriptions.Item>Password</Descriptions.Item>
                  <Descriptions.Item>Password</Descriptions.Item>
                  <Descriptions.Item>Chart List</Descriptions.Item> */}
                </Descriptions>

                {renderCards}
              </Col>
            </Card>
          </Col>

          <Col sm={24} md={8}>
            <Card style={chartStyle} headStyle={headStyle} title='작업 이력'>
              <AppHistory />
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(AdminTotal);
