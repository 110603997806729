import React from 'react';
import { Icon } from 'antd';

import { json, totalBoxStyle } from '../Config';

const TotalFunnelDirectRatio =
  (json[json.length - 6].funnel.direct_ratio +
    json[json.length - 5].funnel.direct_ratio +
    json[json.length - 4].funnel.direct_ratio +
    json[json.length - 3].funnel.direct_ratio +
    json[json.length - 2].funnel.direct_ratio +
    json[json.length - 1].funnel.direct_ratio) /
  6;

export default function Widget() {
  return (
    <div>
      <div style={totalBoxStyle} align='center'>
        <h1>
          <Icon type='thunderbolt' />{' '}
          {TotalFunnelDirectRatio.toFixed(1).toLocaleString()}
        </h1>
      </div>
    </div>
  );
}
