// client 코드별 dashboard 초기 화면
import React from 'react';
import { Row, Col, Divider } from 'antd';

// logo image
import logo from './basic.png';

// Config style import
import { imgStyle, textStyle } from './Config';

export default function TitleBox(props) {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Divider orientation={'right'}>
            <img src={logo} alt={logo} style={imgStyle} />
            <span style={textStyle}>{props.subTitle} Data</span>
          </Divider>
        </Col>
      </Row>
    </div>
  );
}
