import React from 'react';
import { Icon } from 'antd';

import { json, totalBoxStyle } from '../Config';

const result1 = json[json.length - 6].pageview.reduce(function add(
  sum,
  currValue
) {
  return sum + currValue;
},
0);
const result2 = json[json.length - 5].pageview.reduce(function add(
  sum,
  currValue
) {
  return sum + currValue;
},
0);
const result3 = json[json.length - 4].pageview.reduce(function add(
  sum,
  currValue
) {
  return sum + currValue;
},
0);
const result4 = json[json.length - 3].pageview.reduce(function add(
  sum,
  currValue
) {
  return sum + currValue;
},
0);
const result5 = json[json.length - 2].pageview.reduce(function add(
  sum,
  currValue
) {
  return sum + currValue;
},
0);
const result6 = json[json.length - 1].pageview.reduce(function add(
  sum,
  currValue
) {
  return sum + currValue;
},
0);

const TotalPageview = result1 + result2 + result3 + result4 + result5 + result6;
const DayAveragePageview =
  TotalPageview /
  ((json[json.length - 6].pageview.length +
    json[json.length - 5].pageview.length +
    json[json.length - 4].pageview.length +
    json[json.length - 3].pageview.length +
    json[json.length - 2].pageview.length +
    json[json.length - 1].pageview.length) /
    6);

export default function Widget() {
  return (
    <div>
      <div align='right'>
        일일 평균 : {Math.round(DayAveragePageview).toLocaleString()}
      </div>
      <div style={totalBoxStyle} align='center'>
        <h1>
          <Icon type='border' /> {TotalPageview.toLocaleString()}
        </h1>
      </div>
    </div>
  );
}
