import { Button, Typography } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
// import { useSelector } from 'react-redux';

// main img
import mainImage from '../../images/coursemos-vertical-full.png';

// Typography
const { Title } = Typography;

const divStyle = {
  display: 'block',
  marginTop: '15%',
  textAlign: 'center',
  justifyContent: 'center',
};
const imgStyle = {
  width: '165px',
};
const textStyle = {
  level: 2,
  color: '#777',
  marginBottom: '40px',
};
const buttonStyle = {
  color: '#fff',
  background: '#74CBFC',
  border: '1px solid #74CBFC',
};

function NotFoundPage({ history }) {
  // const user = useSelector((state) => state.user);

  return (
    <div style={divStyle}>
      <img src={mainImage} alt={mainImage} style={imgStyle} />
      <div>&nbsp;</div>
      <Title level={textStyle.level} style={textStyle}>
        페이지가 없습니다.
      </Title>
      <Button style={buttonStyle} onClick={() => history.push(`/login`)}>
        Login 화면으로 이동
      </Button>
      <br />
      <br />
      <Button style={buttonStyle} onClick={() => history.goBack()}>
        전 페이지로 이동
      </Button>
    </div>
  );
}

export default withRouter(NotFoundPage);
