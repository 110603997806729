import React from 'react';
import { Icon } from 'antd';

import { json, totalBoxStyle } from '../Config';

const TotalDeviceDesktopRatio =
  (json[json.length - 6].device.desktop_ratio +
    json[json.length - 5].device.desktop_ratio +
    json[json.length - 4].device.desktop_ratio +
    json[json.length - 3].device.desktop_ratio +
    json[json.length - 2].device.desktop_ratio +
    json[json.length - 1].device.desktop_ratio) /
  6;

export default function Widget() {
  return (
    <div>
      <div style={totalBoxStyle} align='center'>
        <h1>
          <Icon type='desktop' />{' '}
          {TotalDeviceDesktopRatio.toFixed(1).toLocaleString()}
        </h1>
      </div>
    </div>
  );
}
