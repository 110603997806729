import React from 'react';
import BillboardChart from 'react-billboardjs';
import 'react-billboardjs/lib/billboard.css';

import { json } from '../Config';

const data = {
  type: 'bar',
  json: {
    data1: [
      json[json.length - 6].activity_resource.notice,
      json[json.length - 6].activity_resource.file,
      json[json.length - 6].activity_resource.folder,
      json[json.length - 6].activity_resource.outline,
      json[json.length - 6].activity_resource.webdoc,
      json[json.length - 6].activity_resource.url,
      json[json.length - 6].activity_resource.movie,
    ],
    data2: [
      json[json.length - 5].activity_resource.notice,
      json[json.length - 5].activity_resource.file,
      json[json.length - 5].activity_resource.folder,
      json[json.length - 5].activity_resource.outline,
      json[json.length - 5].activity_resource.webdoc,
      json[json.length - 5].activity_resource.url,
      json[json.length - 5].activity_resource.movie,
    ],
    data3: [
      json[json.length - 4].activity_resource.notice,
      json[json.length - 4].activity_resource.file,
      json[json.length - 4].activity_resource.folder,
      json[json.length - 4].activity_resource.outline,
      json[json.length - 4].activity_resource.webdoc,
      json[json.length - 4].activity_resource.url,
      json[json.length - 4].activity_resource.movie,
    ],
    data4: [
      json[json.length - 3].activity_resource.notice,
      json[json.length - 3].activity_resource.file,
      json[json.length - 3].activity_resource.folder,
      json[json.length - 3].activity_resource.outline,
      json[json.length - 3].activity_resource.webdoc,
      json[json.length - 3].activity_resource.url,
      json[json.length - 3].activity_resource.movie,
    ],
    data5: [
      json[json.length - 2].activity_resource.notice,
      json[json.length - 2].activity_resource.file,
      json[json.length - 2].activity_resource.folder,
      json[json.length - 2].activity_resource.outline,
      json[json.length - 2].activity_resource.webdoc,
      json[json.length - 2].activity_resource.url,
      json[json.length - 2].activity_resource.movie,
    ],
    data6: [
      json[json.length - 1].activity_resource.notice,
      json[json.length - 1].activity_resource.file,
      json[json.length - 1].activity_resource.folder,
      json[json.length - 1].activity_resource.outline,
      json[json.length - 1].activity_resource.webdoc,
      json[json.length - 1].activity_resource.url,
      json[json.length - 1].activity_resource.movie,
    ],
  },
  names: {
    data1: json[json.length - 6].category,
    data2: json[json.length - 5].category,
    data3: json[json.length - 4].category,
    data4: json[json.length - 3].category,
    data5: json[json.length - 2].category,
    data6: json[json.length - 1].category,
  },
  labels: {
    // colors: '#333',
    show: true,
  },
  // custom colors
  colors: {
    data1: '#38A0FB',
    data2: '#54E7A6',
    data3: '#FBBC44',
    data4: '#F66178',
    data5: '#8C76D7',
    data6: '#79C7FF',
  },
};

const bar = {
  padding: 3,
};

const axis = {
  // rotated: true,
  x: {
    type: 'category',
    categories: ['공지사항', '파일', '폴더', '개요', '웹문서', 'URL', '동영상'],
  },
};

const grid = {
  x: {
    show: true,
  },
  y: {
    show: true,
  },
};

export default function Chart() {
  return <BillboardChart data={data} axis={axis} grid={grid} bar={bar} />;
}
