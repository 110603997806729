import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Auth from 'hoc/auth';
import { Helmet } from 'react-helmet';

// view pages
import LandingPage from 'components/views/LandingPage/LandingPage.js';
import LoginPage from 'components/views/LoginPage/LoginPage.js';
import RegisterPage from 'components/views/RegisterPage/RegisterPage.js';
// NotFound
import NotFound from 'components/views/NotFound/NotFound';
// admin pages
import AdminTotal from 'components/views/AdminPage/AdminTotal';
import MonitorTotal from 'components/views/MonitorPage/MonitorTotal';
// Routers
import Routers from 'components/Routes/Routers';

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>코스모스 차트 - Coursemos Charts</title>
      </Helmet>
      <div>
        <Switch>
          <Route exact path='/' component={Auth(LandingPage, true)} />
          <Route exact path='/login' component={Auth(LoginPage, false)} />
          <Route exact path='/register' component={Auth(RegisterPage, null)} />

          <Route
            exact
            path='/charts/adm/total'
            component={Auth(AdminTotal, true, true, null)}
          />

          <Route
            exact
            path='/charts/obs/total'
            component={Auth(MonitorTotal, true, null, true)}
          />

          {/* Routers */}
          <Routers />

          {/* NotFound route */}
          <Route path='*' component={NotFound} />
        </Switch>
      </div>
    </Suspense>
  );
}

export default App;
