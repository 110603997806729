import React from 'react';
import { Icon } from 'antd';

import { json, totalBoxStyle } from '../Config';

const TotalLogin =
  json[json.length - 6].login +
  json[json.length - 5].login +
  json[json.length - 4].login +
  json[json.length - 3].login +
  json[json.length - 2].login +
  json[json.length - 1].login;
const DayAverageLogin = TotalLogin / (30 * 4 * 6);

export default function Widget() {
  return (
    <div>
      <div align='right'>
        일일 평균 : {Math.round(DayAverageLogin).toLocaleString()}
      </div>
      <div style={totalBoxStyle} align='center'>
        <h1>
          <Icon type='down-circle' /> {TotalLogin.toLocaleString()}
        </h1>
      </div>
    </div>
  );
}
