import React from 'react';
import { Icon } from 'antd';

import { json, totalBoxStyle } from '../Config';

const TotalDeviceTabletRatio =
  (json[json.length - 6].device.mobile_ratio +
    json[json.length - 5].device.mobile_ratio +
    json[json.length - 4].device.mobile_ratio +
    json[json.length - 3].device.mobile_ratio +
    json[json.length - 2].device.mobile_ratio +
    json[json.length - 1].device.mobile_ratio) /
  6;

export default function Widget() {
  return (
    <div>
      <div style={totalBoxStyle} align='center'>
        <h1>
          <Icon type='tablet' />{' '}
          {TotalDeviceTabletRatio.toFixed(1).toLocaleString()}
        </h1>
      </div>
    </div>
  );
}
